/**
 * Module for communicating with Back-end server.
 */

export default class Backend {

    /**
     * Performs a HTTP GET call returning a promise with json data or an error promise.
     *
     * @return body in json format
     */
    async get(url, queryParams, keycloak) {

        const headers = new Headers();

        console.log("REST_API : ", url);

        console.log("queryParams : ", queryParams);

        console.log("keycloak : ", keycloak);

        headers.append("Authorization", 'Bearer ' + keycloak.token);

        const response = await fetch(url + queryParams, {
            headers: headers
        });
        if (!response.ok) {
            throw (response);
        }
        return response.json(); 

    }

    /**
     * Performs a HTTP POST call. Returns no data. Raises an error in case of error on the back-end side.
     *
     * @return response object
     */
    async post(url, data) {

        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(data)
        });
        if (!response.ok) {
            throw (response);
        }
        return response;
    }
}