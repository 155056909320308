/**
 * General utility methods.
 */
export default class Utils {

    /**
     * Extracts value of the query parameter with the specified name from the url.
     * Returns null if not found.
     *
     * @param {*} name Query parameter name
     * @param {*} url Whole url to examine
     */
    getParameterByName(name, url = window.location.href) {
        const escapedName = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + escapedName + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);
        if (!results || !results[2]) return null;
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    /**
     * Clones JavaScript object.
     */
    copyObject(inputObject) {
        return JSON.parse(JSON.stringify(inputObject));
    }

    /**
 * returns the addition for selected key
 * @param {*} additions  selection of additions
 * @param {*} additionKey addition to return
 */
    getAddition(additions, additionKey) {

        if (additions) {
            let addition = additions.filter(addition => addition.addKey === additionKey)[0];
            if (addition) {
                return addition;
            }
        }

        return { "additionKey": "" };

    }
}