import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ProviderTableRow.css';

import BookingAmount from '../booking/BookingAmount';
import BookingHasErrors from '../booking/BookingHasErrors';

import BusinessBookingModal from '../booking/BusinessBookingModal';
import ServiceGroupTableRow from '../serviceGroup/ServiceGroupTableRow';

export default class ProviderTableRow extends Component {

    constructor(props, context) {
        super(props, context);

        this.toggle = this.toggle.bind(this);

        this.state = {
            collapse: false,
        };
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    handleShow(periode, provider, typeId, status) {
        this.props.handleShow(periode, provider, typeId, status);
    }

    handleClose() {
        this.props.handleClose();
    }

    render() {

        const provider = this.props.provider;

        let providerBookings = provider.bookings.map((booking, index) => {

            if (provider.serviceGroups.length > 1) {
                return (
                    <td key={index} ><BookingAmount booking={booking} /><BookingHasErrors booking={booking}/></td>
                )
            } else {
                return (
                    <td key={index} >
                        <label onClick={this.handleShow.bind(this, booking.periode, this.props.provider.alias, null, null)}><BookingAmount booking={booking} /> </label>
                        <label onClick={this.handleShow.bind(this, booking.periode, this.props.provider.alias, null, 'ERROR')}><BookingHasErrors booking={booking} /> </label>
                    </td>
                )
            }

        });

        let serviceGroups
        if (provider.serviceGroups && provider.serviceGroups.length > 1) {
            serviceGroups = provider.serviceGroups.map((serviceGroup, index) => {
                return (
                    <ServiceGroupTableRow serviceGroup={serviceGroup} provider={provider} businessBookings={this.props.businessBookings} key={index} handleShow={this.handleShow.bind(this)} handleClose={this.handleClose.bind(this)} show={this.props.show}
                        handlePageChange={this.props.handlePageChange} totalItemsCount={this.props.totalItemsCount} keycloak={this.props.keycloak}
                        getCsvFile={this.props.getCsvFile} isLoadingCsv={this.props.isLoadingCsv} isLoadingBusinessBookings={this.props.isLoadingBusinessBookings}/>

                )
            });
        }

        return (
            <React.Fragment key={provider.alias}>
                <tr className="provider-header" onClick={this.toggle} style={{ height: "55px" }} >
                    <td>
                        <div style={{ width: "10px" }}>

                        </div>
                    </td>
                    <td className="provider-name">
                        {provider.serviceGroups && provider.serviceGroups.length > 1 ?
                            this.state.collapse ?
                                <FontAwesomeIcon icon="caret-down" color="#555" size="1x" className="service-group-icon"/>
                                :
                                <FontAwesomeIcon icon="caret-right" color="#555" size="1x" className="service-group-icon"/>

                            : null
                        }
                        {provider.name}
                    </td>
                    <React.Fragment>

                        {providerBookings}

                        {/* <BusinessBookingModal businessBookings={this.props.businessBookings} handleClose={this.handleClose.bind(this)} show={this.props.show}/> */}
                        <BusinessBookingModal businessBookings={this.props.businessBookings} handleClose={this.handleClose.bind(this)} show={this.props.show}
                            handlePageChange={this.props.handlePageChange} totalItemsCount={this.props.totalItemsCount} keycloak={this.props.keycloak} 
                            getCsvFile={this.props.getCsvFile} isLoadingCsv={this.props.isLoadingCsv} isLoadingBusinessBookings={this.props.isLoadingBusinessBookings}/>
                    </React.Fragment>
                </tr>
                {this.state.collapse ? serviceGroups : null}

            </React.Fragment>
        );
    }

}