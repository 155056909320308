import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faCaretRight,
    faCaretDown,
    faInfoCircle,
    faSignOutAlt,
    faExclamationCircle,
    faFileCsv
} from "@fortawesome/free-solid-svg-icons";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/App';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import './components/default.css';

const HTML_TAG = 'booking-overview';

/**
 * Web component definition and binding to React application.
 */
class BookingWebComponent extends HTMLElement {

    constructor(props) {
        super(props);

        // Load icons
        library.add(faCaretRight, faCaretDown, faInfoCircle, faSignOutAlt, faExclamationCircle, faFileCsv);
    }

    connectedCallback() {
        this.renderReactApp();
    }

    renderReactApp() {
        // enter React World
        ReactDOM.render( < App />, this);
        }
    }

    // our entire react app is exposed as an HTML element
    const customElementExists = !!customElements.get(HTML_TAG);

    // prevent defining tag multiple times
    if (!customElementExists) {
        customElements.define(HTML_TAG, BookingWebComponent);
    }