import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';

import './Autocomplete.css';


  function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  
  function getSuggestions(value, data) {
    const escapedValue = escapeRegexCharacters(value.trim());
    
    if (escapedValue === '') {
      return [];
    }
  
    const regex = new RegExp('^' + escapedValue, 'i');
  
    return data.filter(d => regex.test(d.ametiqContractNr) || regex.test(d.name));
  }
  
  function getSuggestionValue(suggestion) {
    return suggestion.name;
  }
  
  function renderSuggestion(suggestion) {
    return (
      <span>{suggestion.name}</span>
    );
  }
  
  export default class Autocomplete extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
        value: '',
        suggestions: [],
        data: props.data
      };    
    }
  
    onChange = (event, { newValue, method }) => {
      this.setState({
        value: newValue
      });

      if(method === 'type' && newValue === ''){
        this.props.searchCategories();
      }
     
    };
    
    onSuggestionsFetchRequested = ({ value, reason } ) => {
      this.setState({
        suggestions: getSuggestions(value, this.state.data)
      });
    };
  
    onSuggestionsClearRequested = () => {
      this.setState({
        suggestions: []
      });
    };

    onSuggestionSelected= (event, suggestion) => {

      this.props.searchCategories(suggestion.suggestion.ametiqContractNr);
    };
  
    render() {
      
      const { value, suggestions } = this.state;
      const inputProps = {
        placeholder: "amétiq contract nr",
        value,
        onChange: this.onChange
      };
  
      return (
        <Autosuggest 
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps} />
      );
    }
  }