import React, { Component } from 'react';

import './CategoryResultTable.css';

import CategoryResultTableRow from './CategoryResultTableRow';

import DateUtils from '../../utils/DateUtils';



export default class CategoryResultTable extends Component {

    constructor(props) {
        super(props);

        this.dateUtils = new DateUtils();

        this.state = {
            dateUtils: this.dateUtils // date-related helper functions
        }
    }


    render() {

        var categories = this.props.categories;
        var dateUtils = this.state.dateUtils;
        var view = this.props.view;

        if (!categories) {
            categories = [];
        }

        let services = categories.map((category) => {
            return (
                <CategoryResultTableRow key={category.alias} category={category} dateUtils={dateUtils} months={this.props.months} view={view} keycloak={this.props.keycloak}
                ametiqContractNr={this.props.ametiqContractNr}/>

            )
        });


        let periodes;
        if (categories.length > 0) {
            periodes = categories[0].bookings.map((booking) => {

                if(view === 0 ){
                    return <th key={booking.periode}>{dateUtils.formateDayToTitleOutput(booking.periode)}</th>
                }
                else{
                    return <th key={booking.periode}>{dateUtils.formateMonthToTitleOutput(booking.periode)}</th>

                }
            });
        }

        return (
            <div className="table-responsive">
                <table className="table table-hover main-table mb-0">
                    <thead >
                        <tr className="category-result-table-header">
                            <th style={{ width: "1%" }}> </th>
                            <th style={{ width: "20%" }}>Service</th>

                            {periodes}
                        </tr>
                    </thead>
                    <tbody>
                        {services}
                    </tbody>
                </table>
            </div>
        );
    }
}