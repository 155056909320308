import React, { Component } from 'react';
import './BusinessBookingModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoadingOverlay from 'react-loading-overlay';


import BusinessBookingOverview from '../booking/BusinessBookingOverview';

import { Modal } from 'react-bootstrap';

export default class BusinessBookingModal extends Component {



  render() {

    return (

      <Modal show={this.props.show} onHide={this.props.handleClose} className="modal-business-booking">
        <LoadingOverlay
          active={this.props.isLoadingCsv}
          spinner
          text='Loading the csv file...'
        >
          <Modal.Header>
            <div >
              <div className="col">
                <div className="card mb-3">
                  <FontAwesomeIcon icon="file-csv" size="1x" onClick={this.props.getCsvFile.bind(this)} className="load-csv"/>
                </div>
              </div>
            </div>

          </Modal.Header>
          <Modal.Body>

            <BusinessBookingOverview businessBookings={this.props.businessBookings} handlePageChange={this.props.handlePageChange} totalItemsCount={this.props.totalItemsCount} keycloak={this.props.keycloak} 
            isLoadingBusinessBookings={this.props.isLoadingBusinessBookings}/>


          </Modal.Body>
          <Modal.Footer>
            <button onClick={this.props.handleClose} className="btn-primary">
              Back
          </button>
          </Modal.Footer>
        </LoadingOverlay>
      </Modal >

    );
  }
}