import React, { Component } from 'react';

import './BookingTableRow.css';

export default class BookingTableRow extends Component {

    render() {

        const booking = this.props.booking;
        const utils = this.props.utils;

        return (
            <React.Fragment key={booking.reqId}>
                <tr className="booking-row" style={{ height: "55px" }} >
                    <td>{booking.reqId}</td>
                    <td>{booking.bundle}</td>
                    <td>{booking.route}</td>
                    <td>{booking.transactionDate}</td>
                    <td>{booking.transactionStatus}</td>
                    <td>{utils.getAddition(booking.additions, "errorCode").addValue}</td>
                    <td>{utils.getAddition(booking.additions, "errorDescription").addValue}</td>
                </tr>

            </React.Fragment>
        );
    }

}