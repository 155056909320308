/**
 * Methods to help with handling dates in JavaScript.
 */
export default class Utils {

    formateMonthToTitleOutput(date) {

        if(typeof date === 'string'){

            let year = date.substring(0,4);
            let month = date.substring(4,6) - 1;

            date = new Date(year, month);
        }

        if(date instanceof Date && !isNaN(date)){
            const month = date.toLocaleString('de-CH', { month: 'long' });
            return month;
        } else{
            return null;
        }
    }

    formateDayToTitleOutput(date) {

        if(typeof date === 'string'){

            let year = date.substring(0,4);
            let month = date.substring(4,6) - 1;
            let day = date.substring(6,8);

            date = new Date(year, month, day);
        }

        if(date instanceof Date && !isNaN(date)){
            const day = date.toLocaleString('de-CH', { weekday: 'long' });
            return day;
        } else{
            return null;
        }
    }

    formatePeriodeToDayString(inputString) {

        if(typeof inputString === 'string'){

            let year = inputString.substring(0,4);
            let month = inputString.substring(4,6) - 1;
            let day = inputString.substring(6,8);

            inputString = new Date(year, month, day);
        }

        if(inputString instanceof Date && !isNaN(inputString)){
            var y = inputString.getFullYear(), m = inputString.getMonth(), d = inputString.getDate();
            var firstDay = new Date(y, m, d);

            var dateString = new Date(firstDay.getTime() - (firstDay.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];

            return dateString;
        } else{
            return null;
        }
    }

    formatePeriodeToFirstDayOfMonth(inputString) {

        if(typeof inputString === 'string'){

            let year = inputString.substring(0,4);
            let month = inputString.substring(4,6) - 1;

            inputString = new Date(year, month);
        }

        if(inputString instanceof Date && !isNaN(inputString)){
            var y = inputString.getFullYear(), m = inputString.getMonth();
            var firstDay = new Date(y, m, 1);

            var dateString = new Date(firstDay.getTime() - (firstDay.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];

            return dateString;
        } else{
            return null;
        }
    }

    formatePeriodeToLastDayOfMonth(inputString) {
        
        if(typeof inputString === 'string'){

            let year = inputString.substring(0,4);
            let month = inputString.substring(4,6) - 1;

            inputString = new Date(year, month);
        }
        
        if(inputString instanceof Date && !isNaN(inputString)){
            var y = inputString.getFullYear(), m = inputString.getMonth();
            var lastDay = new Date(y, m + 1, 0);

            var dateString = new Date(lastDay.getTime() - (lastDay.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];

            return dateString;
        } else{
            return null;
        }
    }

}