import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProviderTableRow from '../provider/ProviderTableRow';
import BookingAmount from '../booking/BookingAmount';
import BookingHasErrors from '../booking/BookingHasErrors';

import Backend from '../../services/Backend';

import './CategoryResultTableRow.css';

export default class CategoryResultTableRow extends Component {

    constructor(props, context) {
        super(props, context);

        this.REST_API = process.env.REACT_APP_REST_SERVER_URL;

        this.toggle = this.toggle.bind(this);

        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);

        this.backend = new Backend(this.config);

        this.state = {
            collapse: false,
            backend: this.backend,
            businessBookings: undefined,
            show: false,
            dateUtils: this.props.dateUtils,
            activePage: 1,
            numberOfRows: 30,
            selectedMonth: undefined,
            selectedProvider: undefined,
            selectedTypeId: undefined,
            totalItemsCount: 0,
            maxItemsPerPage: 20,
            fromDate: undefined,
            toDate: undefined,
            hasMoreItems: true,
            selectedStatus: undefined,
            isLoadingCsv: false,
            isLoadingBusinessBookings: false
        };
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }


    handleClose() {
        this.setState({ show: false });
    }

    handleShow(month, provider, typeId, status) {

        this.setState({
            show: true,
            selectedMonth: month,
            selectedProvider: provider,
            selectedTypeId: typeId,
            selectedStatus: status
        },
            function () {
                this.setState({
                    businessBookings: []
                });
                this.getBusinessBookings(month, provider, null, typeId, status);
            }
        );
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber },
            function () {
                this.getBusinessBookings(this.state.selectedMonth, this.state.selectedProvider, pageNumber, this.state.selectedTypeId, this.state.selectedStatus);
            });
    }

    onFromDateChange(fromDate) {
        this.setState({ fromDate: fromDate },
            function () {
                this.getBusinessBookings(this.state.selectedMonth, this.state.selectedProvider, this.state.offset, this.state.selectedTypeId, this.state.selectedStatus);
            });
    }

    render() {

        const category = this.props.category;
        const index = this.props.index;

        let providers
        if (category.providers) {
            providers = category.providers.map((provider, index) => {
                return (
                    <ProviderTableRow provider={provider} businessBookings={this.state.businessBookings} key={index} handleShow={this.handleShow.bind(this)} handleClose={this.handleClose.bind(this)} show={this.state.show}
                        handlePageChange={this.handlePageChange.bind(this)} totalItemsCount={this.state.totalItemsCount} keycloak={this.props.keycloak}
                        getCsvFile={this.getCsvFile.bind(this)} isLoadingCsv={this.state.isLoadingCsv} isLoadingBusinessBookings={this.state.isLoadingBusinessBookings}/>

                )
            });
        }


        let periodes = [];
        if (category.bookings) {
            periodes = category.bookings.map((booking, index) => {
                return (
                    /*                 <td onClick={this.handleShow.bind(this, booking.periode)}><BookingAmount amount={booking.amount} status={booking.status} /></td>*/
                    <td key={booking.periode + index}>
                        <BookingAmount booking={booking} />
                        <BookingHasErrors booking={booking} />
                    </td>

                )
            });
        }
        else {

            for (let i = 0; i < this.props.months + 1; i++) {
                periodes.push(<td key={i}></td>);
            }
        }

        let periodesEmpty = [];
        for (let i = 0; i < this.props.months + 1; i++) {
            periodesEmpty.push(<td key={i}></td>);
        }

        return (
            <React.Fragment>
                <tr className="category-header" onClick={this.toggle} index={index} >
                    <td>
                        <div style={{ width: "10px" }}>
                            {category.providers ?
                                this.state.collapse ?
                                    <FontAwesomeIcon icon="caret-down" color="#555" size="1x" />
                                    :
                                    <FontAwesomeIcon icon="caret-right" color="#555" size="1x" />

                                : null
                            }
                        </div>
                    </td>
                    <td>{category.name}</td>

                    {!this.state.collapse ? periodes : periodesEmpty}
                    {/* <BusinessBookingModal businessBookings={this.state.businessBookings} handleClose={this.handleClose.bind(this)} show={this.state.show} 
                        activePage={this.state.activePage} numberOfRows={this.state.numberOfRows} handlePageChange={this.handlePageChange.bind(this)} /> */}
                </tr>
                {this.state.collapse ? providers : null}
            </React.Fragment>
        );
    }

    getCsvFile = () => {

        const headers = new Headers();

        let queryParams = this.getQueryParams(this.state.selectedMonth, this.state.selectedProvider, null, this.state.selectedTypeId, this.state.selectedStatus, true);

        headers.append("Authorization", 'Bearer ' + this.props.keycloak.token);
        headers.append("Accept", "text/csv");
        this.setState({ isLoadingCsv: true });

        console.log("getCsvFile");

        console.log("query params: ", queryParams);
        try {
            fetch(this.REST_API + "/api/booking/v1/bookings-csv" + queryParams, {
                headers: headers
            })
                .then(response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'Bookings-' + this.state.selectedProvider + ".csv";
                        console.log("download file");
                        this.setState({ isLoadingCsv: false });
                        a.click();
                    });
                    //window.location.href = response.url;
                })
                .catch(error =>{
                    console.log(error);
                    this.setState({ isLoadingCsv: false });
                }

                );
        } catch (error) {
            console.error(error);
            this.setState({ isLoadingCsv: false });
            //const errorMessage = "FEHLER" + error;
            //this.setState({error: true, errorMessage: errorMessage});
        }
    }

    async getBusinessBookings(periode, provider, offset, typeId, status) {

        try {
            this.setState({ isLoadingBusinessBookings: true });

            let businessBookings = [];
            let queryParams = this.getQueryParams(periode, provider, offset, typeId, status);



            const response = await this.backend.get(this.REST_API + '/api/booking/v1/bookings/', queryParams, this.props.keycloak);
            //this.verifyResponse(response);

            console.log('response booking service _ category result:', response);


            if (response.numberOfRows === 0) {
                response.services = [];
            }

            if (!this.state.businessBookings.length) {
                businessBookings = response.services;
            } else {
                businessBookings = this.state.businessBookings;
                businessBookings = businessBookings.concat(response.services);
            }

            this.setState({
                businessBookings: businessBookings,
                totalItemsCount: response.numberOfRows,
                isLoadingBusinessBookings: false
            });

        } catch (error) {
            console.error(error);
            this.setState({ isLoadingBusinessBookings: false });

            //const errorMessage = "FEHLER" + error;
            //this.setState({error: true, errorMessage: errorMessage});
        }
    }

    getQueryParams(periode, provider, offset, typeId, status, isCsvFile) {

        let queryParams = "/?";

        let fromDateString;
        let toDateString;

        switch (this.props.view) {
            case 0:

                if (periode) {
                    fromDateString = this.state.dateUtils.formatePeriodeToDayString(periode);
                    toDateString = this.state.dateUtils.formatePeriodeToDayString(periode);

                }
                else {
                    fromDateString = this.state.dateUtils.formatePeriodeToDayString(this.props.selectedMonth)
                    toDateString = this.state.dateUtils.formatePeriodeToDayString(this.props.selectedMonth)
                }

                break;
            case 1:

                if (periode) {
                    fromDateString = this.state.dateUtils.formatePeriodeToFirstDayOfMonth(periode);
                    toDateString = this.state.dateUtils.formatePeriodeToLastDayOfMonth(periode);

                }
                else {
                    fromDateString = this.state.dateUtils.formatePeriodeToFirstDayOfMonth(this.props.selectedMonth)
                    toDateString = this.state.dateUtils.formatePeriodeToLastDayOfMonth(this.props.selectedMonth)
                }

                break;
            default:
                console.log("View could not be found.");
        }

        queryParams += "format=BUSINESS_GROUP";
        queryParams += "&fromDate=" + fromDateString;
        queryParams += "&toDate=" + toDateString;
        queryParams += "&categoryId=" + this.props.category.id;

        if(this.props.ametiqContractNr){
            queryParams += "&contractNr=" + this.props.ametiqContractNr;
        }

        if (provider) {
            queryParams += "&providerAlias=" + provider;
        }
        if (status) {
            queryParams += "&statusName=" + status;
        }
        queryParams += "&order=STATUS";
        if (!isCsvFile) {
            queryParams += "&limit=" + this.state.maxItemsPerPage;
        }

        if (offset) {
            queryParams += "&offset=" + (offset - 1) * this.state.maxItemsPerPage;
        }

        if (typeId) {
            queryParams += "&typeId=" + typeId;
        }
        return queryParams;
    }

}