import React, { Component } from 'react';

import './BusinessBookingOverview.css';

import { BeatLoader } from 'react-spinners';

import BusinessBookingTableRow from './BusinessBookingTableRow';


export default class BusinessBookingOverview extends Component {


  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      totalItemsCount: props.totalItemsCount

    };
  }

  componentDidMount() {
    // Detect when scrolled to bottom.
    this.refs.myscroll.addEventListener("scroll", () => {
      if (
        this.refs.myscroll.scrollTop + this.refs.myscroll.clientHeight >=
        this.refs.myscroll.scrollHeight
      ) {
        this.setState({
          activePage: this.state.activePage + 1
        },
          function () {
            this.handlePageChange(this.state.activePage);
          }
        );

      }
    });
  }

  handlePageChange(pageNumber) {
    if (this.props.businessBookings.length <= this.state.totalItemsCount) {
      this.props.handlePageChange(pageNumber);
    }

  }

  componentDidUpdate(prevProps) {
    if (prevProps.businessBookings !== this.props.businessBookings) {
      this.setState({ loading: false });
    }
    if (prevProps.totalItemsCount !== this.props.totalItemsCount) {
      this.setState({
        totalItemsCount: this.props.totalItemsCount
      });
    }
  }

  render() {

    const loader = <tr><td colSpan={9}><BeatLoader
      css={"display: block; margin: 0 auto; width: fit-content;"}
      sizeUnit={"px"}
      size={15}
      color={'#A9DB7D'}
      loading={true}
    /></td></tr>;

    let bookings;
    if (this.props.businessBookings && this.props.businessBookings.length > 0) {
      bookings = this.props.businessBookings.map((businessBooking, index) => {
        return (
          <BusinessBookingTableRow key={index} businessBooking={businessBooking} index={index} keycloak={this.props.keycloak} />
        )
      });
    } else if (this.props.isLoadingBusinessBookings) {
      bookings =
        <tr>
          <td>
            <BeatLoader
              css={"display: block; margin: 0 auto; width: fit-content;"}
              sizeUnit={"px"}
              size={15}
              color={'#A9DB7D'}
              loading={true}
            />
          </td>
        </tr>
    } else {
      bookings =
      <tr>
        <td>
            No entries where found.
        </td>
      </tr>

    }

    let header;

    if (this.props.businessBookings && this.props.businessBookings.length > 0 && this.props.businessBookings[0].categoryAlias === 'LABOR') {
      header =

        <React.Fragment>
          <th>Provider GLN</th>
          <th>Partner Trx Id</th>
          <th>Partner Patienten Id</th>
          <th>Device</th>
        </React.Fragment>
    }

    if (this.props.businessBookings && this.props.businessBookings.length > 0 && this.props.businessBookings[0].categoryAlias === 'INVOICE') {
      header =

        <React.Fragment>
          <th>Invoice Nr.</th>
        </React.Fragment>
    }


    return (
      <div >
        <div className="col">
          <div className="card mb-3">
            <div className="card-body">
              <div className="table-responsive infinite-scroll-parent" ref="myscroll" >
                <table className="table table-hover main-table mb-0" >
                  <thead>
                    <tr className="category-result-table-header">
                      <th style={{ width: "1%" }}> </th>
                      <th >Amelie Trx Id</th>
                      <th >Start Date</th>
                      <th >Last Modified</th>
                      <th>Status</th>
                      <th>Contract Nr.</th>
                      {header}
                    </tr>
                  </thead>
                  <tbody>
                    {bookings}
                    {this.state.loading
                      ? loader
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}