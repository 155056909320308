import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Logout extends Component {

  logout() {
    this.props.keycloak.logout();
  }

  render() {
    return (
      <button onClick={ () => this.logout() } className="btn-primary">
        <FontAwesomeIcon icon="sign-out-alt" size="1x" /> Logout
      </button>
    );
  }
}