import React, { Component } from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './BookingHasErrors.css';

export default class BookingHasErrors extends Component {

  render() {

    const booking = this.props.booking;
    let amountLabel;
    let popoverStatusError;

    if (booking) {
      popoverStatusError = (
        <Popover id="popover-status-error" >
          Es gibt noch {booking.numberOfErrors} fehlerhafte Transaktion(en).
        </Popover>
      );

      if (booking.status && booking.status.toUpperCase() === 'ERROR') {
        amountLabel = <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popoverStatusError}
        popperConfig={{
          modifiers: {
            preventOverflow: {
              enabled: false
            }
          }
        }} >
          <div className="overlay-div"><FontAwesomeIcon icon="exclamation-circle" size="1x" color="#B22567" /></div>
        </OverlayTrigger>;
      }
    }

    return (
      <label>
        {amountLabel}
      </label>
    );
  }
}