import React, { Component } from 'react';

import './ServiceGroupTableRow.css';

import BookingAmount from '../booking/BookingAmount';
import BookingHasErrors from '../booking/BookingHasErrors';


import BusinessBookingModal from '../booking/BusinessBookingModal';

export default class ServiceGroupTableRow extends Component {

    handleShow(periode, provider, typeId, status) {
        this.props.handleShow(periode, provider, typeId, status);
    }

    handleClose() {
        this.props.handleClose();
    }

    render() {

        const serviceGroup = this.props.serviceGroup;
        const provider = this.props.provider;

        let serviceGroupBookings = serviceGroup.bookings.map((booking, index) => {
            return (
                <td key={index} >
                    <label onClick={this.handleShow.bind(this, booking.periode, this.props.provider.alias, serviceGroup.id, null)} ><BookingAmount booking={booking}/></label>
                    <label onClick={this.handleShow.bind(this, booking.periode, this.props.provider.alias, serviceGroup.id, 'ERROR')}><BookingHasErrors booking={booking}/></label>
                </td>
            )
        });

        return (
            <React.Fragment key={provider.alias}>
                <tr className="service-group-header" style={{ height: "55px" }} >
                    <td>
                        <div style={{ width: "10px" }}>

                        </div>
                    </td>
                    <td className="service-group-name">{serviceGroup.name}</td>
                    <React.Fragment>

                        {serviceGroupBookings}

                        {/* <BusinessBookingModal businessBookings={this.props.businessBookings} handleClose={this.handleClose.bind(this)} show={this.props.show}/> */}
                        <BusinessBookingModal businessBookings={this.props.businessBookings} handleClose={this.handleClose.bind(this)} show={this.props.show} 
                                            handlePageChange={this.props.handlePageChange} totalItemsCount={this.props.totalItemsCount} keycloak={this.props.keycloak}
                                            getCsvFile={this.props.getCsvFile} isLoadingCsv={this.props.isLoadingCsv} isLoadingBusinessBookings={this.props.isLoadingBusinessBookings}/>
                    </React.Fragment>
                </tr>


            </React.Fragment>
        );
    }

}