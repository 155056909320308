import React, { Component } from 'react';

import Autocomplete from '../autocomplete/Autocomplete';

import './CategorySearch.css';

export default class CategorySearch extends Component {

    constructor(props) {
        super(props);
        this.contractNrRef = React.createRef();
    }

    searchCategories() {
        this.props.searchCategories(this.contractNrRef.current.value, this.props.keycloak);
    }

    render() {

        return (
            <div>
                <div className="col">
                    <div className="card mb-3">
                        <div className="card-body">
                            {this.props.organisations ?
                                <Autocomplete data={this.props.organisations} filterOption={this.props.organisations.ametiqContractNr} searchCategories={this.props.searchCategories} />
                                :
                                <React.Fragment>
                                    <input name="contractNr" type="number" placeholder="Contract Nr." ref={this.contractNrRef} className="input-primary"
                                            onKeyPress={event => {
                                                if (event.key === 'Enter') {
                                                    this.searchCategories();
                                                }
                                            }}/> 
                                    <button onClick={this.searchCategories.bind(this)} className="btn-primary">Search</button>
                                </React.Fragment>
                            }

                        </div>
                    </div>
                </div>
            </div>

        );
    }
}