import React, { Component } from 'react';
import NumberUtils from '../../utils/NumberUtils';

import './BookingAmount.css';

export default class BookingAmount extends Component {

  constructor(props) {
    super(props);

    this.numberUtils = new NumberUtils();

}

  render() {

    const booking = this.props.booking;

    return (
      <React.Fragment>
        {this.numberUtils.formatAmount(booking.numberOfBookings)}
      </React.Fragment>
    );
  }
}