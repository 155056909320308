/**
 * Methods to help with dealing with numbers in JavaScript.
 */
export default class NumberUtils {

    /**
     * Formats an amount as string using selected language (locale of that language).
     */
    formatAmount(amount) {
        const formatter = new Intl.NumberFormat('de-CH');
        if (amount !== undefined) {
            return formatter.format(this.parseNumber(amount));
        }
        return 0;
    }

    /**
     * Converts a number expressed as string to a number.
     */
    parseNumber(numberString) {
        return parseFloat(numberString);
    }

    electronicFormat(iban){
        const NON_ALPHANUM = /[^a-zA-Z0-9]/g;
        return iban.replace(NON_ALPHANUM, '').toUpperCase();
    }

    /**
     * Formats IBAN given as string to a proper IBAN format.
     */
    parseIBAN(iban) {
        const EVERY_FOUR_CHARS =/(.{4})(?!$)/g;
        const separator = ' ';

        return this.electronicFormat(iban).replace(EVERY_FOUR_CHARS, "$1" + separator);
    }

    /**
     * Rounds a number on two decimals using default rounding.
     * throws Error in case when a value is not a number.
     */
    round(num) {
        if (isNaN(num)) {
            throw new Error('A number must be given.');
        }
        return Math.round(num * 100) / 100;
    }

    /**
     * Returns a number for a given number string in a given locale.
     */
    parseNumberWithLocale(value) {
        const example = Intl.NumberFormat('de-CH').format('1.1');
        const cleanPattern = new RegExp(`[^-+0-9${example.charAt(1)}]`, 'g');
        const cleaned = value.replace(cleanPattern, '');
        const normalized = cleaned.replace(example.charAt(1), '.');

        return parseFloat(normalized);
    }
}