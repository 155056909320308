import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BeatLoader } from "react-spinners";

import { Popover, OverlayTrigger } from 'react-bootstrap';


import './BusinessBookingTableRow.css';

import Utils from '../../utils/Utils';

import Backend from '../../services/Backend';

import BookingTableRow from './BookingTableRow';


export default class BusinessBookingTableRow extends Component {

    constructor(props) {
        super(props);

        this.REST_API = process.env.REACT_APP_REST_SERVER_URL;

        this.utils = new Utils();

        this.backend = new Backend(this.config);

        this.toggle = this.toggle.bind(this);
        this.state = {
            collapse: false,
            bookings: [],
            backend: this.backend,
            utils: this.utils, // various generic helper methods
            error: false,
            errorMessage: undefined
        };
    }

    toggle(serviceRequestId) {
        this.setState({ collapse: !this.state.collapse });

        if (!this.state.collapse) {
            this.getDetailBookings(serviceRequestId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.businessBooking !== this.props.businessBooking) {
            this.setState({ collapse: false });
        }
    }

    render() {

        let className = "category-header";
        const businessBooking = this.props.businessBooking;
        const index = this.props.index;

        const utils = this.state.utils;
        const additions = businessBooking.additions;

        if (businessBooking.statusName === 'error') {
            className = className.concat(" category-header-error");
        }

        let bookings = this.state.bookings.map((booking, index) => {
            return (
                <BookingTableRow booking={booking} key={index} utils={utils} />

            )
        });

        let bookingsTable = null;

        if (this.state.bookings.length > 0) {
            bookingsTable = (
                <tr>
                    <td></td>
                    <td colSpan="8" className="booking-table">
                        <table className="table main-table mb-0">
                            <thead className="category-result-table-header light">
                                <tr>
                                    <th>Request Id</th>
                                    <th>Bundle</th>
                                    <th>Route</th>
                                    <th>Transaction Date</th>
                                    <th>Transaction Status</th>
                                    <th>Error Code</th>
                                    <th style={{ width: "25%" }}>Error Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bookings}
                            </tbody>
                        </table>
                    </td>
                </tr>
            );
        }
        else if (this.state.error) {
            bookingsTable = <tr><td></td><td colSpan="8">An error occured {this.state.errorMessage}</td></tr>

        }
        else {
            bookingsTable = <tr><td></td><td colSpan="8">
                <BeatLoader
                    css={"display: block; margin: 0 auto; width: fit-content;"}
                    sizeUnit={"px"}
                    size={15}
                    color={"#A9DB7D"}
                    loading={true}
                />
            </td></tr>
        }

        let popoverContract = (
            <Popover id="popover-organisation-name" >
                {businessBooking.organisationName}
            </Popover>
        );


        let labRows;
        if (businessBooking && businessBooking.categoryAlias === 'LABOR') {
            labRows =

                <React.Fragment>
                    <td>{utils.getAddition(additions, "providerGln").addValue}</td>
                    <td>{utils.getAddition(additions, "partnerTrxId").addValue}</td>
                    <td>{utils.getAddition(additions, "partnerPatientId").addValue}</td>
                    <td>{utils.getAddition(additions, "RiboxName").addValue}</td>
                </React.Fragment>
        }

        let invoiceRows;
        if (businessBooking && businessBooking.categoryAlias === 'INVOICE') {
            invoiceRows =

                <React.Fragment>
                    <td>{utils.getAddition(additions, "invoiceId").addValue}</td>
                </React.Fragment>
        }


        return (
            <React.Fragment>
                <tr className={className} onClick={this.toggle.bind(this, businessBooking.serviceReqId)} index={index}>
                    <td>
                        <div style={{ width: "10px" }}>
                            {this.state.collapse ?
                                <FontAwesomeIcon icon="caret-down" size="1x" />
                                :
                                <FontAwesomeIcon icon="caret-right" size="1x" />
                            }
                        </div>
                    </td>
                    <td>{businessBooking.serviceReqId}</td>
                    <td>{businessBooking.createDate}</td>
                    <td>{businessBooking.maxDateResult}</td>
                    <td>{businessBooking.statusName}</td>
                    <td><label>
                        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popoverContract} >
                            <div className="overlay-div">{businessBooking.contractNr}</div>
                        </OverlayTrigger>
                    </label></td>
                    {labRows}
                    {invoiceRows}
                </tr>
                {this.state.collapse ? bookingsTable : null}
            </React.Fragment>
        );
    }

    async getDetailBookings(serviceReqId) {

        try {
            let queryParams = "/?serviceReqId=" + serviceReqId;

            const response = await this.backend.get(this.REST_API + '/api/booking/v1/bookings', queryParams, this.props.keycloak);
            //this.verifyResponse(response);

            if (response.bookings === null) {
                response.bookings = [];
            }

            this.setState({
                bookings: response.bookings
            });

        } catch (error) {
            console.error(error);
        }
    }

}